import * as XLSX from "xlsx";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { axiosInstanceAuth } from "../../apiInstances/index";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";

import { Dialog, DialogActions, DialogContent, DialogContentText, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import "./user.css";

const User = () => {
  const { encryptData, decryptData } = useEncryption();
  let navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [requestData, setRequestData] = useState([]);
  const [oldRequestData, setOldRequestData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openEditPopUp, setOpenEditPopUp] = useState(false);
  const [isEdit, setIsEdit] = useState([]);
  const [nodes, setNodes] = useState([{}]);

  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleEdit = (data) => {
    setIsEdit([data]);
    setOpenEditPopUp(true);
  };
  const handleCloseEditPopUp = () => {
    setOpenEditPopUp(false);
  };
  const getUser = (data) => {
    try {
      const encryptedData = encryptData(
        JSON.stringify({
          page_no: data.page_no,
          search: data.search,
        })
      );
      axiosInstanceAuth
        .get(`/admin/viewUsers`, {
          data: encryptedData,
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);
          console.log("🚀 ~ responseData", responseData.data);

          responseData.data.sort((a, b) => {
            return parseFloat(a.nodes) - parseFloat(b.nodes);
          });
          if (responseData.status) {
            setPage(Math.ceil(res.data.count / 10));

            const temp = [];
            // data.role === "user" &&

            responseData.data.map((data, index) => {
              temp.push({
                id: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {index + 1}
                  </MDTypography>
                ),
                full_name: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {`${data.walletAddress} `}
                  </MDTypography>
                ),

                node: (
                  <MDTypography
                    component="a"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                    onClick={() => console.log("hello")}
                  >
                    {data.nodes}
                    {setNodes((prev) => [...prev, data.nodes])}
                  </MDTypography>
                ),
                create: (
                  <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                    {new Date(data.createdAt).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </MDTypography>
                ),
                lastlogin: (
                  <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                    {data.lastLogged === "" ? (
                      <div className="desh"> ------------ </div>
                    ) : (
                      <div>
                        {new Date(data.lastLogged).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                      </div>
                    )}
                  </MDTypography>
                ),

                rewards: (
                  <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                    {data.rewards}
                  </MDTypography>
                ),
                rewardswithdrawn: (
                  <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                    {data.withdrawnRewards}
                  </MDTypography>
                ),
                referralRewards: (
                  <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                    {data.referralRewards}
                  </MDTypography>
                ),
                block: (
                  <div>
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        type="submit"
                        className="TitleColor"
                        onClick={() => blockuser(data)}
                      >
                        {`${data.status}`}
                      </MDButton>
                    </MDTypography>
                  </div>
                ),

                withdrawnblock: (
                  <div>
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        type="submit"
                        className="TitleColor"
                        onClick={() => withdrawalblock(data)}
                      >
                        {`${data.withdrawalStatus}`}
                      </MDButton>
                    </MDTypography>
                  </div>
                ),
                withdrawReferralBlocked: (
                  <div>
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        type="submit"
                        className="TitleColor"
                        onClick={() => withdrawReferralBlocked(data)}
                      >
                        {`${data.withdrawReferralBlocked}`}
                      </MDButton>
                    </MDTypography>
                  </div>
                ),
              });
            });
            setOldRequestData(temp);
            setRequestData(temp);

            setLoading(false);
          }
        })
        .catch((res) => {
          toast.error(res.data.message);
        });
    } catch (error) {}
  };

  const downloadReport = (data) => {
    try {
      const encryptedData = encryptData();
      axiosInstanceAuth
        .get(`/admin/viewUsers`, {
          data: encryptedData,
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);
          const workSheet = XLSX.utils.json_to_sheet(responseData.data);
          const workBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workBook, workSheet, "users");
          //Buffer
          let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
          //Binary string
          XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
          //Download
          XLSX.writeFile(workBook, "userData.xlsx");
        })
        .catch((res) => {
          toast.error(res.data.message);
        });
    } catch (error) {}
  };

  const blockuser = (data) => {
    try {
      const encryptedData = encryptData(
        JSON.stringify({
          walletAddress: data.walletAddress,
        })
      );
      axiosInstanceAuth
        .post(`/admin/blockUser`, {
          data: encryptedData,
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);
          toast.success(responseData.message);
          try {
            console.log("========>>>>>>>>>>>>>>>>>>>>>>>>");
            getUser({ page_no: currentPage, search: "" });
          } catch (error) {}
        })
        .catch((res) => {
          toast.error(res.data.message);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const withdrawalblock = (data) => {
    try {
      const encryptedData = encryptData(
        JSON.stringify({
          walletAddress: data.walletAddress,
        })
      );
      axiosInstanceAuth
        .post(`/admin/withdrawBlock`, {
          data: encryptedData,
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);
          toast.success(responseData.message);
          try {
            console.log("========>>>>>>>> =====>>>>>>>");
            getUser({ page_no: currentPage, search: "" });
          } catch (error) {}
        })
        .catch((res) => {
          toast.error(res.data.message);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const withdrawReferralBlocked = (data) => {
    try {
      const encryptedData = encryptData(
        JSON.stringify({
          walletAddress: data.walletAddress,
        })
      );
      axiosInstanceAuth
        .post(`/admin/withdrawReferralBlocked`, {
          data: encryptedData,
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);
          console.log("🚀 ~ .then ~ responseData", responseData);
          toast.success(responseData.message);
          try {
            console.log("========>>>>>>>> =====>>>>>>>");
            getUser({ page_no: currentPage, search: "" });
          } catch (error) {}
        })
        .catch((res) => {
          toast.error(res.data.message);
        });
    } catch (error) {
      console.error(error);
    }
  };
  const columns = [
    { Header: "id", accessor: "id", align: "left" },
    { Header: "Wallet Address", accessor: "full_name", align: "left" },
    { Header: "OwnedNodes    ", accessor: "node", align: "left" },
    { Header: "Created On", accessor: "create", align: "left" },
    { Header: "LastLogin", accessor: "lastlogin", align: "left" },
    { Header: "Rewards", accessor: "rewards", align: "left" },
    { Header: "Rewards Withdrawn", accessor: "rewardswithdrawn", align: "left" },
    { Header: "Referral Rewards", accessor: "referralRewards", align: "left" },
    { Header: "Block", accessor: "block", align: "left" },
    { Header: "Withdrawn Block", accessor: "withdrawnblock", align: "left" },
    { Header: "Withdraw Referral Blocked", accessor: "withdrawReferralBlocked", align: "left" },
  ];
  // nodes referralRewards
  const handleInput = (e) => {
    if (e.target.value) {
      const searchData = oldRequestData.filter((i) => {
        return (
          i?.email?.props?.children?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          i?.full_name?.props?.children?.toLowerCase().includes(e.target.value.toLowerCase())
        );
      });
      setRequestData(searchData);
    } else {
      setRequestData(oldRequestData);
    }
    // getUser({ page_no: currentPage, search: e.target.value });
  };

  const onPaginationTable = (event, page) => {
    setCurrentPage(page);
    getUser({ page_no: page, search: "" });
  };

  const viewUserDetails = (data) => {
    localStorage.setItem("profile", JSON.stringify(data));
    navigate("/profile");
  };

  useEffect(() => {
    getUser({ page_no: currentPage, search: "" });
  }, []);

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        {loading ? (
          <div className="snippet" data-title=".dot-spin">
            <div className="stage">
              <div className="dot-spin"></div>
            </div>
          </div>
        ) : (
          <MDBox pt={6} pb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="coinTitleColor"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <div className="box-user">
                      <MDTypography variant="h6" color="white">
                        User
                      </MDTypography>
                      <MDButton
                        onClick={downloadReport}
                        variant="contained"
                        color="info"
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        Report
                      </MDButton>
                    </div>
                  </MDBox>
                  <MDBox pt={3}>
                    <MDBox pr={1} classNane="inputsre">
                      {/* <MDInput
                        type="text"
                        label="Search here"
                        value={searchInput}
                        onChange={handleInput}
                      /> */}
                      <TextField type="text" label="Search here" onChange={handleInput} />
                    </MDBox>

                    <DataTable
                      table={{ columns: columns, rows: requestData }}
                      // isSorted={true}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />

                    {requestData.length > 0 && (
                      <Stack padding={2}>
                        <Pagination onChange={onPaginationTable} count={page} size="large" />
                      </Stack>
                    )}
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        )}
        <Footer />
      </DashboardLayout>
      {/* Edit Faiths Form Pop Up */}
      <Dialog open={openEditPopUp} onClose={handleCloseEditPopUp}>
        <MDBox className="job-status-form-conatiner">
          <DialogContent>
            <DialogContentText>
              <MDBox className="close-btn">
                <MDButton variant="text" color="black">
                  <CloseIcon onClick={handleCloseEditPopUp} />
                </MDButton>
              </MDBox>
              <MDBox className="Heading">
                <MDTypography variant="h3">Edit</MDTypography>
              </MDBox>
              <MDBox component="form" role="form" className="form-content">
                <div>
                  {isEdit.map((item) => (
                    <div>
                      <MDBox mb={2}>
                        <MDInput
                          type="email"
                          name="userEmail"
                          label="Email"
                          fullWidth
                          value={item.email}
                          // onChange={userInput}
                          required
                        />
                      </MDBox>

                      <MDBox mb={2}>
                        <MDInput
                          type="password"
                          name="UserPassword"
                          label="Password"
                          fullWidth
                          // onChange={userInput}
                        />
                      </MDBox>
                    </div>
                  ))}
                </div>
              </MDBox>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton
              variant="contained"
              color="info"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              Update
            </MDButton>
          </DialogActions>
        </MDBox>
      </Dialog>
    </>
  );
};

export default User;
