import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Coin from "layouts/coin";
import AddNode from "layouts/addnode";
import EditNode from "layouts/editnode";
import SendNode from "layouts/sendnode";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Terms from "layouts/TermsAndConditions";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import invest from "./assets/images/invest.png";
import FAQ from "layouts/faq";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import Investment from "layouts/how_to_invest";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import Articles from "layouts/articles";
import ViewNode from "layouts/viewnode";
import RevokeNode from "layouts/revokenodes";
import SetRewards from "layouts/setrewards";
import PersonIcon from "@mui/icons-material/Person";
import ReadMoreArticle from "layouts/articles/ReadMoreArticle";
import UserProfile from "layouts/userProfile";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import User from "layouts/user";
import SendReward from "layouts/sendReward";
import RevokeReward from "layouts/revokeReward";
import ShieldIcon from "@mui/icons-material/Shield";
import PrivateWalletKey from "layouts/privateWalletKey";

const routes = [
  {
    // type: "collapse",
    // name: "Dashboard",
    // key: "dashboard",
    // icon: <DashboardIcon color="primary" />,
    // icon: <Icon fontSize="small">dashboard</Icon>,
    // route: "/dashboard",
    // component: <Dashboard />,
  },

  // {
  //   type: "collapse",
  //   name: "Add Node",
  //   key: "addnode",
  //   icon: <AddCircleIcon />,
  //   // icon: <img fontSize="small" src="/Images/bitcoin2.png" width={40} height={40} alt="img" />,
  //   route: "/addnode",
  //   component: <Coin />,
  // },
  {
    type: "collapse",
    name: "Change Remaining Nodes",
    key: "changenode",
    icon: <ChangeCircleIcon />,
    // icon: <img fontSize="small" src="/Images/bitcoin2.png" width={40} height={40} alt="img" />,
    route: "/changenode",
    component: <AddNode />,
  },
  {
    type: "collapse",
    name: "Private Wallet",
    key: "privatekey",
    icon: <ShieldIcon />,
    // icon: <img fontSize="small" src="/Images/bitcoin2.png" width={40} height={40} alt="img" />,
    route: "/privatekey",
    component: <PrivateWalletKey />,
  },
  {
    type: "collapse",
    name: "AddNews",
    key: "addnews",
    icon: <AddCircleIcon />,
    // icon: <img fontSize="small" src="/Images/bitcoin2.png" width={40} height={40} alt="img" />,
    route: "/addnews",
    component: <Articles />,
  },
  {
    type: "collapse",
    name: "Edit Nodes Price",
    key: "editnode",
    icon: <EditIcon />,
    // icon: <img fontSize="small" src="/Images/bitcoin2.png" width={40} height={40} alt="img" />,
    route: "/editnode",
    component: <EditNode />,
  },
  {
    type: "collapse",
    name: "Send Hash ",
    key: "sendnode",
    icon: <SendIcon />,
    // icon: <img fontSize="small" src="/Images/bitcoin2.png" width={40} height={40} alt="img" />,
    route: "/sendnode",
    component: <SendNode />,
  },
  {
    type: "collapse",
    name: "Revoke Hash",
    key: "revoke",
    icon: <SendIcon />,
    route: "/revokenodes",
    component: <RevokeNode />,
  },
  {
    type: "collapse",
    name: "Set Rewards",
    key: "setRewards",
    icon: <SendIcon />,
    route: "/setRewards",
    component: <SetRewards />,
  },
  {
    type: "collapse",
    name: "Send Rewards",
    key: "sendRewards",
    icon: <SendIcon />,
    route: "/sendRewards",
    component: <SendReward />,
  },
  {
    type: "collapse",
    name: "Revoke Rewards",
    key: "revokeRewards",
    icon: <SendIcon />,
    route: "/revokeRewards",
    component: <RevokeReward />,
  },
  // {
  //   type: "collapse",
  //   name: "User Investment",
  //   key: "",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/Tables",
  //   component: <Tables />,
  // },

  // {
  //   type: "collapse",
  //   name: "FAQs",
  //   key: "FAQ",
  //   icon: <LiveHelpIcon />,
  //   route: "/FAQ",
  //   component: <FAQ />,
  // },
  // {
  //   type: "collapse",
  //   name: "Terms And Conditions",
  //   key: "Terms_And_Conditions",
  //   icon: <AssignmentIcon />,
  //   route: "/Terms_And_Conditions",
  //   component: <Terms />,
  // },
  // {
  //   type: "collapse",
  //   name: "How To Invest",
  //   key: "Invest",
  //   icon: <img src={invest} alt="img" />,
  //   route: "/Invest",
  //   component: <Investment />,
  // },
  {
    type: "collapse",
    name: "HistoryOfNode",
    key: "node",
    icon: <AssignmentIcon />,
    route: "/viewnodes",
    component: <ViewNode />,
  },
  {
    type: "collapse",
    name: "User",
    key: "user",
    icon: <PersonIcon />,
    route: "/user",
    component: <User />,
  },
  {
    // type: "collapse",
    // name: "articles",
    // key: "articles",
    // icon: <Icon fontSize="small">helpoutline</Icon>,
    route: "/readarticle",
    component: <ReadMoreArticle />,
  },

  {
    // type: "collapse",
    //name: "Sign Up",
    // key: "sign-up",
    // icon: <Icon fontSize="small">assignment</Icon>,
    route: "/profile",
    component: <UserProfile />,
  },
  {
    //   type: "collapse",
    //   name: "Sign In",
    //   key: "sign-in",
    //   icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    // type: "collapse",
    //name: "Sign Up",
    // key: "sign-up",
    // icon: <Icon fontSize="small">assignment</Icon>,
    route: "/sign-up",
    component: <SignUp />,
  },
];

export default routes;
