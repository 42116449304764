import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Box from "@mui/material/Box";
import NativeSelect from "@mui/material/NativeSelect";
// import Select, { SelectChangeEvent } from '@mui/material/Select';

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from "@mui/material";
import { axiosInstanceAuth } from "apiInstances";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { toast } from "react-toastify";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";

function SetRewards() {
  let navigate = useNavigate();
  const [title, setTitle] = useState("smart node");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("BUSD");
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);

  const { encryptData, decryptData } = useEncryption();

  const [loading, setLoading] = useState(true);

  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    checkToken();
  });

  const handleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  const userInput = (event) => {
    setPrice(event.target.value);
  };
  // name, currency, price
  const submitHandler = (e) => {
    e.preventDefault();

    try {
      const encryptedData = encryptData(
        JSON.stringify({
          perNodeReward: price,
          name: title,
        })
      );
      axiosInstanceAuth
        .post(`/admin/setRewards`, {
          data: encryptedData,
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);
          console.log(`🚀 ~ responseData`, responseData);

          if (responseData.status) {
            // console.log(responseData);
            toast.success(responseData.message);

            setLoading(false);
          }
        })
        .catch((res) => {
          let err = decryptData(res.data.data);
          console.log("🚀 ~ err", err);
          toast.error(err.message);
        });
    } catch (error) {}
    // console.log(title, "title");
    // console.log(price, "price");
  };
  const getUser = (data) => {
    try {
      const encryptedData = encryptData(
        JSON.stringify({
          page_no: data.page_no,
          search: data.search,
        })
      );
      axiosInstanceAuth
        .get(`/admin/viewNodes`, {
          data: encryptedData,
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);
          console.log("🚀 ~ responseData", responseData);

          if (responseData.status) {
            setPage(Math.ceil(res.data.count / 10));

            const temp = [];
            responseData.data.map((data, index) => {
              temp.push({
                id: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {index + 1}
                  </MDTypography>
                ),
                name: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {`${data.name}`}
                  </MDTypography>
                ),
                supplies: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {data.supplies}
                  </MDTypography>
                ),
                quantity: (
                  <MDTypography component="a" variant="button " color="text" fontWeight="medium">
                    {data.quantity}
                  </MDTypography>
                ),
                dummyQuantity: (
                  <MDTypography component="a" variant="button " color="text" fontWeight="medium">
                    {data.dummyQuantity}
                  </MDTypography>
                ),
                supplied_date: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {data.supplied_date}
                  </MDTypography>
                ),
                finance_details: data.finance_details.map((i) => (
                  <div key={i._id}>
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      Price {i.price}
                    </MDTypography>
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      Currency {i.currency}
                    </MDTypography>
                  </div>
                )),

                // walletAddress: (
                //   <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                //     {data.walletAddress}
                //   </MDTypography>
                // ),
                // walletAmount: (
                //   <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                //     {data.walletAmt}
                //   </MDTypography>
                // ),
              });
            });
            setRows(temp);
            setLoading(false);
          }
        })
        .catch((res) => {
          toast.error(res.data.message);
        });
    } catch (error) {}
  };
  useEffect(() => {
    getUser({ page_no: currentPage, search: "" });
  }, []);
  const columns = [
    { Header: "id", accessor: "id", align: "left" },
    { Header: "Name", accessor: "name", align: "left" },
    { Header: "Supplies", accessor: "supplies", align: "left" },
    { Header: "Quantity", accessor: "quantity", align: "left" },
    { Header: "DummyQuantity", accessor: "dummyQuantity", align: "left" },
    { Header: "Supply Date", accessor: "supplied_date", align: "left" },
    { Header: "Finance Details", accessor: "finance_details", align: "left" },
  ];
  const onPaginationTable = (event, page) => {
    setCurrentPage(page);
    getUser({ page_no: page, search: "" });
  };

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Set Rewards
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitHandler}>
                  {/* <InputLabel id="demo-simple-select-label">Choose Node</InputLabel> */}
                  {/* <NativeSelect
                    className="box-height"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={title}
                    label="Title"
                    onChange={handleChange}
                    required
                  >
                    <option value={"power node"}>power node</option>
                    <option value={"smart node"}>smart node</option>
                    <option value={"master node"}>master node</option>
                  </NativeSelect>

                  <NativeSelect
                    className="box-height"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currency}
                    label="Title"
                    onChange={handleCurrencyChange}
                    required
                  >
                    <option value={"BUSD"}>BUSD</option>
                    <option value={"BUSD"}>BUSD</option>
                  </NativeSelect> */}
                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MDInput type="text" name="UserType" label="Node" value={title} />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="UserType"
                        label="Rewards per node"
                        onChange={userInput}
                      />
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                        // onClick={submitHandler}
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>
        {loading ? (
          <div className="snippet" data-title=".dot-spin">
            <div className="stage">
              <div className="dot-spin"></div>
            </div>
          </div>
        ) : (
          <MDBox pt={6} pb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="coinTitleColor"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Nodes
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns: columns, rows: rows }}
                      isSorted={true}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                    {rows.length > 0 && (
                      <Stack padding={2}>
                        <Pagination onChange={onPaginationTable} count={page} size="large" />
                      </Stack>
                    )}
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        )}
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default SetRewards;
